import Vue from 'vue'
import Vuesax from 'vuesax'

import App from './App.vue'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
// Vuex Store
import store from './store/store'
// Theme Configurations
import '../themeConfig.js'
// Perfectscrollbar
// import PerfectScrollbar from "vue2-perfect-scrollbar";
// import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
// Vue.use(PerfectScrollbar);

import Vuebar from 'vuebar';
Vue.use(Vuebar);

// VeeValidate
import VeeValidate from 'vee-validate' 
Vue.use(VeeValidate)

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// moment
import VueMoment from 'vue-moment'
Vue.use(VueMoment)

// for vue-upload-multiple-image
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

// Vue Social Auth Plugin
import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'
Vue.use(VueAxios, axios)
Vue.use(VueSocialauth, {
  providers: {
    google: {
      clientId: "244936211704-jf95ich11dfbidg0uclkjbupm0lui1d5.apps.googleusercontent.com",
      redirectUri: location.origin + "/auth/google/callback"
    },
    facebook: {
      clientId: "237616381850433",
      redirectUri: location.origin + "/auth/facebook/callback"
    }

  }
})

// VueGoodTablePlugin => DATATABLE
import VueGoodTablePlugin from 'vue-good-table';
// import the styles
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

// Theme Configurations
import 'prismjs'
import 'prismjs/themes/prism.css'
import VsPrism from './components/prism/VsPrism.vue';
Vue.component(VsPrism.name, VsPrism);
import VsSidebarGroup from './components/vs-sidebar-group/Sidebar-Group.vue';
Vue.component(VsSidebarGroup.name, VsSidebarGroup);
// i18n
import i18n from './i18n/i18n.js'
// Vue Router
import router from './router'
Vue.config.productionTip = false

import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);


// vSelect
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

Vue.use(Vuesax)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
import '@/assets/scss/style.scss'