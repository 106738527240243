/*=========================================================================================
  File Name: moduleEcommerceGetters.js
  Description: Ecommerce Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  isInCart: state => itemId => {
    return state.cartItems.some((item) => item.id === itemId)
  },
  isProdsheetSerialInCart: state => serial => {
    return state.cartItems.some((item) => item.productsheet ? item.productsheet.serial === serial : false)
  },
  isProdsheetsSerialInCart: state => serial => {
    console.log('state', state)
    console.log('serial', serial)

    var findProductsheetSerial = null
    var exist = false
    state.cartItems.forEach(element => {
      console.log('element.productsheets', element.productsheets)

      if(element.productsheets) {
        findProductsheetSerial = element.productsheets.find((e) => e.serial === serial)
        console.log('findProductsheetSerial', findProductsheetSerial)
        exist = findProductsheetSerial ? true : false
        if(exist) return true
      }
    })
    return false
  },
  getAllProductsheetsInCart: state => {
    var prodsheets = []
    state.cartItems.forEach((cartProduct, index) => {
      if(cartProduct.productsheet) {
        prodsheets.push({
          startEndDateTime: cartProduct.selectedStartEndDateTime,
          indexProductInCart: index,
          numero_serie: cartProduct.productsheet.numero_serie,
          serial: cartProduct.productsheet.serial,
        });
      }

      else if(cartProduct.productsheets) {
        cartProduct.productsheets.forEach(productsheet => {
          prodsheets.push({
            startEndDateTime: cartProduct.selectedStartEndDateTime,
            indexProductInCart: index,
            numero_serie: productsheet.numero_serie,
            serial: productsheet.serial,
          });
        });
      }
    });
    return prodsheets
  },
  isInWishList: state => itemId => {
    return state.wishList.some((item) => item.id === itemId)
  },
  getCartItem: state => itemId => {
    const result = state.cartItems.filter((item) => item.id === itemId)
    return result.length ? result.pop() : []
  },
  getPorductPopup(state) {
    return state.porductPopup;
  },
  getTotalFromPorductPopup(state) {
    return state.porductPopup.total_product
  },
  getApplyCouponResponse(state) {
    return state.applyCouponResponse
  },
  getCartTotal(state, getters) {
    var cartItems = state.cartItems
    var total = 0;
    
    cartItems.forEach((product, index) => {

      console.log('getCartTotal product', product)

      let totalOptions = 0
      if(product.allOptions) {
        product.allOptions.forEach((optionValue, index) => {
          totalOptions = Number(totalOptions) + Number(optionValue.total)
        });
      }
      total = Number(total) + Number(product.total_product) + Number(totalOptions)
    });
    var applyCouponResponse = getters.getApplyCouponResponse
    if(applyCouponResponse != null && applyCouponResponse.type_coupon == 'pourcentage') {
      return parseFloat(total-(total*Number(applyCouponResponse.value)/100)).toFixed(2)
    }

    if(applyCouponResponse != null && applyCouponResponse.type_coupon == 'montant') {
      return parseFloat(total-Number(applyCouponResponse.value)).toFixed(2)
    }
    console.log('getCartTotal total', total)
    return parseFloat(total).toFixed(2)
  }
}
