/*=========================================================================================
  File Name: moduleSettingState.js
  Description: Setting Module State
  ----------------------------------------------------------------------------------------
  Item Name: Lokmi v2.1
  Author: BAKLOUTI HOUSSEM
  COMPANY NAME: NETNOVUS
==========================================================================================*/

export default {
  customerSetting: [],
  companySettings: [],
}