// axios
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_ENDPOINT


const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
}

const createAxios = axios.create({
  baseURL,
  headers
});

// Add a request interceptor
createAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('adminAccessToken')
  if(token) {
    config.headers.common['Authorization'] = `Bearer ${token}`
  }
  const companyUuid = localStorage.getItem('companyUuid')
  if(companyUuid) {
    config.headers.common['X-Tenant'] = `${companyUuid}`
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});  

export default createAxios;

