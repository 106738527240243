/*=========================================================================================
  File Name: moduleEcommerceActions.js
  Description: Ecommerce Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'
import router from '@/router'
const _ = require('lodash'); 
import moment from 'moment';

export default {
  toggleItemInWishList ({ commit }, item) {
    commit('TOGGLE_ITEM_IN_WISH_LIST', item)
  },
  toggleItemInCart ({ getters, commit, dispatch }, item) {
    getters.isInCart(item.id) ? commit('REMOVE_ITEM_FROM_CART', item) : dispatch('additemInCart', item)
  },
  isProdsheetInCart ({ getters }, serial) {
    return getters.isProdsheetSerialInCart(serial)
  },
  isProdsheetsInCart ({ getters }, serial) {
    console.log('from isProdsheetsInCart action serial', serial)
    return getters.isProdsheetsSerialInCart(serial)
  },
  getAllProdsheetsInCart ({ getters }) {
    return getters.getAllProductsheetsInCart()
  },
  totalCart ({ getters }) {
    return getters.getCartTotal()
  },
  removeItemInCart ({ commit }, payload) {
    commit('REMOVE_ITEM_FROM_CART', payload)
  },
  additemInCart ({ commit }, item) {
    commit('ADD_ITEM_IN_CART', item)
  },
  addProductInCart ({ commit }) {
    commit('ADD_PRODUCT_IN_CART')
  },
  formatDateTime({ commit }, payload) {
    return moment(payload.date + " " + payload.time).format('YYYY-MM-DD HH:mm');
  },
  async checkIfProductIsInStock ({ commit, dispatch, state }) {
    let startDate = moment(state.porductPopup.selectedStartEndDateTime.startDate, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY')
    let startTime = moment(state.porductPopup.selectedStartEndDateTime.startTime, 'DD-MM-YYYY hh:mm:ss').format('HH:mm')
    
    let endDate = moment(state.porductPopup.selectedStartEndDateTime.endDate, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY')
    let endTime = moment(state.porductPopup.selectedStartEndDateTime.endTime, 'DD-MM-YYYY hh:mm:ss').format('HH:mm')

    let popupStartDateTime = await dispatch('formatDateTime', { date: startDate, time: startTime })
    let popupEndDateTime = await dispatch('formatDateTime', { date: endDate, time: endTime })
    var totalQuantityRequested = 0
    state.cartItems.forEach(element => {

      let elementStartDate = moment(element.selectedStartEndDateTime.startDate, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY')
      let elementStartTime = moment(element.selectedStartEndDateTime.startTime, 'DD-MM-YYYY hh:mm:ss').format('HH:mm')
      
      let elementEndDate = moment(element.selectedStartEndDateTime.endDate, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY')
      let elementEndTime = moment(element.selectedStartEndDateTime.endTime, 'DD-MM-YYYY hh:mm:ss').format('HH:mm')
  
      let elementStartDateTime = moment(elementStartDate + " " + elementStartTime).format('YYYY-MM-DD HH:mm')
      let elementEndDateTime = moment(elementEndDate + " " + elementEndTime).format('YYYY-MM-DD HH:mm')

      if(Number(element.id) === Number(state.porductPopup.id)) {
        if(
          _.isEqual(element.selectedStartEndDateTime.startDate, state.porductPopup.selectedStartEndDateTime.startDate) &&
          _.isEqual(element.selectedStartEndDateTime.startTime, state.porductPopup.selectedStartEndDateTime.startTime) &&
          _.isEqual(element.selectedStartEndDateTime.endDate, state.porductPopup.selectedStartEndDateTime.endDate) &&
          _.isEqual(element.selectedStartEndDateTime.endTime, state.porductPopup.selectedStartEndDateTime.endTime) ||
          elementEndDateTime >= popupStartDateTime && elementStartDateTime <= popupEndDateTime
          ) {
          totalQuantityRequested = Number(totalQuantityRequested) + Number(element.quantityRequested)
        }
      }
      
    });
    // check if out of stock
    if(Number(state.porductPopup.calculated) < Number(totalQuantityRequested) + Number(state.porductPopup.quantityRequested)) {
      return false
    }
    
    return true
  },
  addSelectedDateInCart ({ commit }, item) {
    commit('ADD_ITEM_IN_CART', item)
  },
  addSelectedStartEndDateTimeInCart ({ commit }, item) {
    commit('ADD_SELECTED_START_END_DATETIME_IN_CART', item)
  },
  updateItemQuantity ({ commit }, payload) {
    commit('UPDATE_ITEM_QUANTITY', payload)
  },
  updateItemInCart ({ commit }, payload) {
    commit('UPDATE_ITEM_IN_CART', payload)
  },
  btnUpdateCartItem ({ commit }, payload) {
    commit('BTN_UPDATE_ITEM_CART', payload)
  },
  clearCart ({ commit }) {
    commit('CLEAR_CART')
  },
  createEmptyPopup ({ commit }, payload) {
    commit('CREATE_EMPTY_POPUP', payload)
  },
  async createEmptyPopup2 ({ commit }, payload) {
    commit('CREATE_EMPTY_POPUP2', payload)
  },
  async updateItemInCart2 ({ commit, dispatch }, payload) {
    await dispatch('createEmptyPopup2') // attendre que `createEmptyPopup2` soit finie
    commit('UPDATE_ITEM_IN_CART', payload)
  },
  popupChangeQuantity ({ commit }, qty) {
    commit('CHANGE_PRODUCT_QTY_POPUP', qty)
  },
  popupChangeSelectedStartEndDateTime ({ commit }, selectedStartEndDateTime) {
    commit('CHANGE_SELECTED_START_END_DATE_POPUP', selectedStartEndDateTime)
  },
  /*
  getExistingProductFromCart({ state }, productId) {
    console.log('getExistingProductFromCart')
    console.log('getExistingProductFromCart productId', productId)

    let cartItems = state.cartItems;
    let cartItemsTmp = []

    cartItems.forEach(productCartItem => {

      if(productId == productCartItem.id) {

        cartItemsTmp.push({
          id: productCartItem.id,
          quantityRequested: productCartItem.quantityRequested,
          selectedStartEndDateTime: productCartItem.selectedStartEndDateTime,
        })
      }
    });

    console.log('cartItemsTmp', cartItemsTmp)
  },*/
  changeSelectedCartItem ({ commit }, index) {
    commit('CHANGE_SELECTED_CART_ITEM_INDEX', index)
  },
  changeTheValueOfUpdateButton ({ commit }, boolValue) {
    commit('CHANGE_THE_VALUE_Of_UPDATE_BTN', boolValue)
  },
  popupChangeProductOptionQuantity ({ commit }, payload) {
    commit('CHANGE_PRODUCTOPTION_QTY_POPUP', payload)
  },
  setPaymentMethod ({ commit }, paymentMethod) {
    console.log('paymentMethod', paymentMethod)
    commit('SET_PAYMENT_METHOD', paymentMethod)
  },
  setSelectedClientNewContract ({ commit }, item) {
    commit('SET_SELECTED_CLIENT_NEW_CONTRACT', item)
  },
  submitReservation ({ commit, rootState }, payload) {

    return new Promise((resolve, reject) => {
      payload.loading()

      var _this = this;

      axios.post(`/api/v2/addreservation`, {data: payload.submitReservation} )
        .then((response) => {
          console.log('response.status', response.status); // return 200

          if(response.status == 200) {
            commit('CLEAR_CART')
            // Close animation if passed as payload
            if (payload.closeAnimation) payload.closeAnimation()
            
            payload.loading.close()

            // redirect to thank you page
            // location.reload();
            router.push({ path: `/${router.currentRoute.params.slug}/confirm-order/${response.data.identifier}`})
  
            /*
            payload.dialog({
              color:'success',
              title: `Succès`,
              text: `Votre réservation a été validé. CODE: ${response.data.identifier}`,
              acceptText: 'Ok',
            })
            */
          }

          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  submitReservationStripeCheckout ({ commit, rootState }, payload) {

    return new Promise((resolve, reject) => {
      payload.loading()

      axios.post(`/api/v4/paiement/stripe/checkout`, {data: payload.submitReservation} )
        .then((response) => {
          commit('CLEAR_CART')
          payload.loading.close()
          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  submitReservationFromAdmin ({ commit, rootState }, payload) {

    return new Promise((resolve, reject) => {
      payload.loading()

      var _this = this;

      axios.post(`/api/v4/admin/add-reservation`, {data: payload.submitReservation} )
        .then((response) => {
          console.log('response.status', response.status); // return 200

          if(response.status == 200) {
            commit('CLEAR_CART')
            commit('CLEAR_SELECTED_CLIENT_CONRTACT')
            commit('SET_ID',response.data.identifier)
            commit('CLEAR_COUPON_CODE')
            // Close animation if passed as payload
            if (payload.closeAnimation) payload.closeAnimation()
            
            payload.loading.close()
          }

          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  updateReservationFromAdmin ({ commit, rootState }, payload) {

    return new Promise((resolve, reject) => {
      payload.loading()

      var _this = this;

      axios.put(`/api/v4/admin/update-reservation`, {data: payload.submitReservation} )
        .then((response) => {
          console.log('response.status', response.status); // return 200

          if(response.status == 200) {
            commit('CLEAR_CART')
            commit('CLEAR_COUPON_CODE')
            // Close animation if passed as payload
            if (payload.closeAnimation) payload.closeAnimation()
            
            payload.loading.close()

            router.push({ path: `/admin/location/reservation/${payload.submitReservation.reservationUniqueId}` })
  
            payload.notify({
              time: 8000,
              title: 'Succès',
              text: 'Mise à jour terminée avec succès',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
              position: 'top-right'
            })
      
          }

          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  payWithMonetico ({ commit }, payload) {
    console.log('payload', payload)

    return new Promise((resolve, reject) => {
      axios.post(`api/paiement/monetico`, payload.data )
        .then((response) => {
          if(response.status == 200) {
            console.log('response', response)
           
          }

          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  downloadPDF ({ commit, rootState }, payload) {
    console.log('payload', payload)

    return new Promise((resolve, reject) => {
      axios.post(`/api/v2/downloadPDF`, {uniqueid : payload.reservationData.uniqueid} )
        .then((response) => {
          console.log('response.status', response.status); // return 200

          if(response.status == 200) {
            // location.reload();
            // redirect to thank you page => router.push(router.currentRoute.query.to || '/')
  
            // Close animation if passed as payload
            if (payload.closeAnimation) payload.closeAnimation()

            payload.loading.close()
  
            payload.dialog({
              color:'success',
              title: `Succès`,
              text: `TEST`,
              acceptText: 'Ok',
            })
          }

          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  getAvProduct ({ commit, state }, payload) {

    return new Promise((resolve, reject) => {
      payload.loading()
      
      /*
      const params = {
        'startDate': payload.startDate,
        'startTime': payload.startTime,
        'endDate': payload.endDate,
        'endTime': payload.endTime,
      }

      if(payload.productId) {
        params['quantity'] = payload.quantity ? payload.quantity : 1
        params['productId'] = payload.productId
      }
      console.log('params', params)
      */

      axios.post(`/api/v2/checkavailibilityproducts?startDate=${payload.startDate}&startTime=${payload.startTime}&endDate=${payload.endDate}&endTime=${payload.endTime}&productId=${payload.productId?payload.productId:''}&excludeReservationProductUuid=${payload.excludeReservationProductUuid?payload.excludeReservationProductUuid:''}&quantity=${payload.quantity?payload.quantity:1}&withoutDiscount=${Number(state.isWithoutDiscountActive)}`)
        .then((response) => {
          console.log('response.status', response.status);

          if(response.status == 200) {
            
            payload.loading.close()
          }

          resolve(response)
        })
        // If error notify
        .catch(function (err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Error',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    })
  },
  searchProdsheetSuggestion ({ commit, state }, payload) {

    return new Promise((resolve, reject) => {
      state.loading = true
      axios.get(`/api/v4/search-prodsheet-suggestion?startDate=${payload.startDate}&endDate=${payload.endDate}&productId=${payload.productId}&paginate=${payload.perPage?payload.perPage:10}&page=${payload.page}`)
      .then((response) => {
        console.log('response', response);
        commit('ADD_PRODUCTSHEET_SUGGESTION', response.data)
        resolve(response)
        state.loading = false
      })
      .catch(function (err) {
        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()
        state.loading = false
        })
    })
  },
  getAvProductForThisProductsheetBySerial ({ commit, state }, payload) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/v4/prodsheet/get-price?startDate=${payload.startDate}&endDate=${payload.endDate}&productsheetSerial=${payload.serial}`)
      .then((response) => {
        console.log('response', response);
        resolve(response)
      })
      .catch(function (error) {
        console.log('err', error)
        reject(error)
      })
    })
  },
  applyCoupon ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v4/apply-coupon`, {params: payload.params})
        .then((response) => {
          commit('SET_APPLY_COUPON', response.data)
          resolve(response)
        })
        .catch((error) => {
          commit('CANCEL_APPLY_COUPON')
          payload.loading.close()
          let errors = Object.values(error.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          );
          reject(error)
        })
    })
  },
  getCategList ({ commit }, payload) {
    console.log('getCategList from action')
    commit('TOGGLE_LOADING_CATEG_LIST', true)

    return new Promise((resolve, reject) => {
      axios.get(`/api/v4/categ-list`)
        .then((response) => {
          commit('SET_CATEG_LIST', response.data)
          commit('TOGGLE_LOADING_CATEG_LIST', false)
          resolve(response)
        })
        .catch((error) => {
          commit('TOGGLE_LOADING_CATEG_LIST', false)
          let errors = Object.values(error.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          );
          reject(error)
        })
    })
  },
  cancelApplyCoupon ({ commit }) {
    commit('CANCEL_APPLY_COUPON')
  },
  cancelApplyCouponFromUpdate ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/v4/cancel-apply-coupon`, {params: payload.params})
        .then((response) => {
          commit('CANCEL_APPLY_COUPON', response.data)
          resolve(response)
        })
        .catch((error) => {
          payload.loading.close()
          let errors = Object.values(error.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          );
          reject(error)
        })
    })
  },
  toggleIsWithoutDiscountActive ({ commit }, payload) {
    commit('TOGGLE_IS_WITHOUT_DICOUNT_ACTIVE', payload)
  },
}
