<script>
  export default {
    methods: {
      $can(permissionName) {
        const userInfo = JSON.parse(localStorage.getItem('adminInfo'))
        const exist = userInfo.all_permissions.find((permission) => permission.name == permissionName)
        return typeof exist !== "undefined" ? true : false
      },
    },
  };
</script>