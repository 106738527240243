/*=========================================================================================
  File Name: moduleSettingGetters.js
  Description: Setting Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Lokmi v2.1
  Author: BAKLOUTI HOUSSEM
  COMPANY NAME: NETNOVUS
==========================================================================================*/


export default {}
