/*=========================================================================================
  File Name: moduleSettingMutations.js
  Description: Setting Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Lokmi v2.1
  Author: BAKLOUTI HOUSSEM
  COMPANY NAME: NETNOVUS
==========================================================================================*/


export default {
  SET_RESERVATION_SETTINGS (state, customerSetting) {
    state.customerSetting = customerSetting
  },
  SET_CUSTOMER_SETTINGS (state, customerSetting) {
    state.customerSetting = customerSetting
  },
  SET_COMPANY_SETTINGS (state, companySettings) {
    state.companySettings = companySettings
  },
}
