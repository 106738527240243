import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default new VueI18n({
    locale: 'fr', // set default locale
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: {
        en: {
            CopyrightText: 'All rights reserved',
            Starterkit: 'Starterkit',
            Dashboards: 'Dashboards',
            Classic: 'Classic',
            Comment: 'Comment',
            CommentHere: 'Comment here ..',
            Analytical: 'Analytical ',
            Ecommerce: 'Ecommerce ',
            General: 'General ',
            Apps: 'Apps',
            Chats: 'Chats',
            Calendar: 'Calendar',
            ContactList: 'Contact List',
            ContactGrid: 'Contact Grid',
            Email: 'Email',
            Todo: 'Todo',
            UiElements: 'Ui Elements',
            VuesaxComponents: 'Vuesax Components',
            Alert: 'Alert',
            Avatar: 'Avatar',
            Breadcrumb: 'Breadcrumb',
            Buttons: 'Buttons',
            Cards: 'Cards',
            Chips: 'Chips',
            Collapse: 'Collapse',
            Dialog: 'Dialog',
            Divider: 'Divider',
            Dropdown: 'Dropdown',
            List: 'List',
            Loading: 'Loading',
            Navbar: 'Navbar',
            Notification: 'Notification',
            Numberinput: 'Number input',
            Pagination: 'Pagination',
            Popup: 'Popup',
            Progress: 'Progress',
            Sidebar: 'Sidebar',
            Slider: 'Slider',
            Tabs: 'Tabs',
            Tooltip: 'Tooltip',
            Forms: 'Forms',
            FormElements: 'Form Elements',
            Checkboxes: 'Checkboxes',
            Datepicker: 'Datepicker',
            Input: 'Input',
            Radio: 'Radio',
            Select: 'Select',
            Switch: 'Switch',
            Textarea: 'Textarea',
            Upload: 'Upload',
            LoadMore: 'Load More',
            FormLayouts: 'Form Layouts',
            FormAction: 'Form Action',
            FormBasic: 'Form Basic',
            FormHorizontal: 'Form Horizontal',
            FormWizard: 'Form Wizard',
            DragnDrop: 'Drag n Drop',
            TableExamples: 'Table Examples',
            Basictable: 'Basic table',
            NoDataForTable: 'No data for table',
            Editabletable: 'Editable table',
            ExpandDatatable: 'Expand Datatable',
            Filtertable: 'Filter table',
            Miscellaneoustable: 'Miscellaneous table',
            Paginationtable: 'Pagination table',
            Selectedtable: 'Selected table',
            SSTtable: 'SST table',
            Charts: 'Charts',
            ApexCharts: 'ApexCharts',
            ChartJs: 'ChartJs',
            Icons: 'Icons',
            MaterialIcons: 'Material Icons',
            ThemifyIcons: 'Themify Icons',
            ExtraComponents: 'Extra Components',
            Carousel: 'Carousel',
            Widgets: 'Widgets',
            AppWidgets: 'App Widgets',
            DataWidgets: 'Data Widgets',

            // NEW 
            Created_at: 'Created at',
            Client_list: 'Liste des clients',
            Add: 'Add',
            News: 'News',
            Next: 'Next',
            Back: 'Back',
            Finish: 'Finish',
            Authentication: 'Authentication',
            Login: 'Login',
            Logout: 'Logout',
            LoginHere: 'Login Here',
            LoginHereDescription: 'Welcome back, please login to your account.',
            typeHereToSearch: 'Type here to search',
            ChooseAClient: 'Choose a client',
            CouponActivation: 'Coupon Activation',
            CorrectErrorsDescription: 'Please correct the following error(s):',
            Register: 'Register',
            Remember_me: 'Remember me',
            ForgotPassword: 'Forgot Password',
            ResetPassword: 'Reset Password',
            ResetPasswordText1: 'Enter the email address associated with your account.',
            ResetPasswordText2: 'We will send you a link to that email address to reset your password.',

            customer_reservation: 'Customer reservation',
            rental: 'Rental',
            contract: 'Contrat',
            newContract: 'New Contract',
            delivery: 'Delivery',
            defaultDelivery: 'Default Shipping Method',
            deliveryMethod: 'Delivery Method',
            deliveryPoint: 'Delivery Point',
            paymentGateway: 'Payment Gateway',
            paymentMethod: 'Payment method',
            AdvancePayment: 'Advance Payment',
            Rest: 'Rest',
            Currency: 'Currency',
            Currencies: 'Currencies',
            Category: 'Category',
            Discount: 'Discount',
            Product: 'Product',
            Pricing: 'Pricing',
            Reference: 'Reference',
            Short: 'Short',
            categoryManagement: 'Category Management',
            productManagement: 'Product Management',
            booking: 'Booking',
            settings: 'Settings',
            remove: 'Remove',
            value: 'Value',
            Yes: 'Yes',
            No: 'No',
            Warning: 'Warning',
            RemoveImage: "Remove Image",
            UpdateImage: "Update Image",
            Update: "Update",
            AlertDeleteImage: "This image will be permanently deleted. Do you want to confirm?",
            DragYouPhotosHere: 'Drag you photos here',
            ChooseYourDates: 'Choose your dates',
            ShippingCosts: 'Shipping costs',
            Start: 'Start',
            End: 'End',
            Every_month: 'Every month',
            New_reservation: 'New reservation',
            New_reservation_description: '',
            Products_list: 'Product List',
            Perso_info: 'PERSONAL INFO',
            Payment: 'Payment',
            Result: 'Result',
            Mes_dates: 'My dates',
            Price: 'Price',
            MaxWeight: 'Max Weight',
            MaxHeight: 'Max Height',
            MaxWidth: 'Max Width',
            NoDataAvailable: 'No data available',
            SorryNoMatchingOptions: 'Sorry, no matching options',
            Account: 'Account',
            Quantity: 'Quantity',
            Price_Period: 'Price / Period',
            Confirm: 'Confirm',
            Apply: 'Apply',
            Reserve: 'Reserve',
            Cart: 'Cart',
            ContinueShopping: 'Continue shopping',
            Cart_empty: 'You don\'t have any items in your cart.',
            Choose_another_date: 'Choose Another Date',
            Create_your_account: 'Create your account',
            Personal_info: 'Personal Infomations',
            Create_your_account_description: 'You can register either using your email address or through your Facebook / Twitter / Google account.',
            Success: 'Success',
            alertCreateStore: 'It may take a few minutes to create your store.',
            alertCreateStoreSuccess: 'Your store is being created. A confirmation message will be sent to your e-mail address automatically.',
            SubTitleLogin: 'Welcome back, please login to your account.',
            Company: 'Company',
            Subdomain: 'Subdomain',
			TwoFactorAuthentication: 'Two-factor authentication',
            Name: 'Name',
            Description: 'Description',
            Short_description: 'Short_description',
            Guard: 'Guard',
            State: 'State',
            Role: 'Role',
            CompanyName: 'Company name',
            CompanyLogo: 'Company logo',
            Fullname: 'Fullname',
            Firstname: 'First name',
            Lastname: 'Last name',
            MobileNumber: 'Mobile number',
            ZipCode: 'Zip code',
            confirmCode : 'Confirmation code',
            RoomNumber: 'Room number',
            PassportNumber: 'Passport number',
            Password: 'Password',
            Password_confirmation: 'Confirm the password',
            Address: 'Address',
            City: 'City',
            Edit: 'Edit',
            Cancel: 'Cancel',
            Close: 'Close',
            Website: 'Website',
            Admin: 'Admin',
            Accept_terms_conditions: 'I accept the terms and conditions',
            Alert_reservation_client_step2: 'It is necessary to fill your basket to go to the next step.',
            Alert_reservation_client_step3: 'You must be identified to proceed to the next step.',
            Payment_options: 'Payment Options',
            Payment_options_description: 'Be sure to click on correct payment option',
            Many: 'Many',
        },
        fr: {
            CopyrightText: 'Tous les droits sont réservés',
            Starterkit: 'Kit de démarrage',
            Dashboards: 'Tableaux de bord',
            Classic: 'Tableau de bord classique',
            Comment: 'Commentaire',
            CommentHere: 'Commentez ici ..',
            Analytical: 'Tableau de bord analytique',
            Ecommerce: 'Commerce électronique',
            General: 'Tableau de bord général',
            Apps: 'applications',
            Chats: 'Chats',
            Civility: 'Civilité',
            ProductList: 'Liste des produits',
            Calendar: 'Calendrier',
            ContactList: 'Liste de contacts',
            ContactGrid: 'ContactGrid',
            Email: 'Adresse e-mail',
            Todo: 'Faire',
            UiElements: 'UiElements',
            VuesaxComponents: 'VuesaxComponents',
            Alert: 'Alerte',
            Avatar: 'Avatar',
            Breadcrumb: 'Miette de pain',
            Buttons: 'Boutons',
            Cards: 'Cartes',
            Chips: 'chips',
            Collapse: 'Effondrer',
            Dialog: 'Dialogue',
            Divider: 'Diviseur',
            Dropdown: 'Menu déroulant',
            List: 'liste',
            Loading: 'Chargement',
            Navbar: 'Barre de navigation',
            Notification: 'Notification',
            Numberinput: 'Nombre dentrée',
            Pagination: 'Pagination',
            Popup: 'Apparaitre',
            Progress: 'Le progrès',
            Sidebar: 'Barre latérale',
            Slider: 'Glissière',
            Tabs: 'Onglets',
            Tooltip: 'Info-bulle',
            Forms: 'Formes',
            FormElements: 'FormElements',
            Checkboxes: 'Cases à cocher',
            Datepicker: 'Sélecteur de date',
            Input: 'Contribution',
            Radio: 'Radio',
            Select: 'Sélectionner',
            Switch: 'Commutateur',
            Textarea: 'Textarea',
            Upload: 'Télécharger',
            LoadMore: 'Charger Plus',
            FormLayouts: 'Form Layouts',
            FormAction: 'Form Action',
            FormBasic: 'Form Basic',
            FormHorizontal: 'Form Horizontal',
            FormWizard: 'Form Wizard',
            DragnDrop: 'Drag n Drop',
            TableExamples: 'Table Exemples',
            Basictable: 'Basic table',
            NoDataForTable: 'Aucune donnée disponible',
            Editabletable: 'Editable table',
            ExpandDatatable: 'Développer Datatable',
            Filtertable: 'Table de filtration',
            Miscellaneoustable: 'Miscellaneous table',
            Paginationtable: 'Table de pagination',
            Selectedtable: 'Table sélectionnée',
            SSTtable: 'SSTtable',
            Charts: 'Graphiques',
            ApexCharts: 'ApexCharts',
            ChartJs: 'ChartJs',
            Icons: 'Icônes',
            MaterialIcons: 'Icônes Matérielles',
            ThemifyIcons: 'ThemifyIcons',
            ExtraComponents: 'Extra Components',
            Carousel: 'Carrousel',
            Widgets: 'Widgets',
            AppWidgets: 'App Widgets',
            DataWidgets: 'DataWidgets',

            // NEW
            Created_at: 'Créé à',
            Client_list: 'Client List',
            Add: 'Ajouter',
            News: 'L\'actualité',
            Next: 'Suivant',
            Back: 'Retour',
            Finish: 'Terminer',

            Authentication: 'Authentification',
            Login: 'Connexion',
            Logout: 'Se déconnecter',
            LoginHere: 'Connectez-vous ici',
            LoginHereDescription: 'Bienvenue à nouveau, veuillez vous connecter à votre compte.',
            typeHereToSearch: 'Tapez ici pour rechercher',
            ChooseAClient: 'Choisir un client',
            CouponActivation: 'Activation du coupon',
            CorrectErrorsDescription: 'Veuillez corriger les erreurs suivantes):',
            Register: 'Créer un compte',
            Remember_me: 'Rester connecté',
            ForgotPassword: 'Mot de passe oublié',
            ResetPassword: 'Réinitialiser le mot de passe',
            ResetPasswordText1: 'Saisissez l\'adresse e-mail associé à votre compte.',
            ResetPasswordText2: 'Nous vous enverrons un lien sur cette adresse e-mail pour réinitialiser votre mot de passe.',

            customer_reservation: 'Réservation Client',
            rental: 'Location',
            contract: 'Contract',
            newContract: 'Nouveau Contrat',
            delivery: 'Livraison',
            defaultDelivery: 'Méthode de Livraison par défaut',
            deliveryMethod: 'Méthode de livraison',
            deliveryPoint: 'Point de livraison',
            paymentGateway: 'Passerelle de paiement',
            paymentMethod: 'Mode de paiement',
            AdvancePayment: 'Acompte',
            Rest: 'Reste',
            Currency: 'Devise',
            Currencies: 'Devises',
            Category: 'Catégorie',
            Discount: 'Remise',
            Product: 'Produit',
            Pricing: 'Tarification',
            Reference: 'Référence',
            Short: 'Courte',
            categoryManagement: 'Gestion catégorie',
            productManagement: 'Gestion produit',
            booking: 'Réservation',
            settings: 'Paramètres',
            remove: 'Supprimer',
            value: 'Valeur',
            Yes: 'Oui',
            No: 'Non',
            Warning: 'Avertissement',
            RemoveImage: "Supprimer l'image",
            UpdateImage: "Mettre à jour l'image",
            Update: "Mettre à jour",
            AlertDeleteImage: "Cette image sera supprimé définitivement. Voulez vous confirmer ?",
            DragYouPhotosHere: 'Faites glisser vos photos ici',
            ChooseYourDates: 'Choisissez vos dates',
            ShippingCosts: 'Frais de livraison',
            Start: 'Date de départ',
            End: 'Date de retour',
            Every_month: 'Tous les mois',
            New_reservation: 'Réservez nos prestations en ligne.',
            New_reservation_description: '',
            Products_list: 'Liste des Produits',
            Perso_info: 'Informations personnelles',
            Payment: 'Paiement',
            Result: 'Résultat',
            Mes_dates: 'Mes dates',
            Price: 'Prix',
            MaxWeight: 'Poids Maximum',
            MaxHeight: 'Hauteur Maximum',
            MaxWidth: 'Largeur Maximale',
            NoDataAvailable: 'Pas de données disponibles',
            SorryNoMatchingOptions: "Aucune option n'est disponible",
            Account: 'Compte',
            Quantity: 'Quantité',
            Price_Period: 'Prix / Période',
            Confirm: 'Confirmer',
            Apply: 'Appliquer',
            Reserve: 'Réserver',
            Cart: 'Panier',
            ContinueShopping: 'Continuer vos achats',
            Cart_empty: 'Votre panier est vide.',
            Choose_another_date: 'Choisir Une Autre Date',
            Create_your_account: 'Créer votre compte',
            Personal_info: 'Informations personnelles',
            Create_your_account_description: 'Vous pouvez vous inscrire soit en utilisant votre adresse e-mail, soit via votre compte Facebook / Twitter / Google.',
            Success: 'Succès',
            alertCreateStore: 'La creation de votre boutique peut prendre quelques minutes.',
            alertCreateStoreSuccess: 'Votre boutique est en cours de création. Un message de confirmation vous sera envoyé automatiquement à votre adresse e-mail.',
            SubTitleLogin: 'Bienvenue à nouveau, veuillez vous connecter à votre compte.',
            Company: 'Entreprise',
            Subdomain: 'Sous-domaine',
			TwoFactorAuthentication: 'Authentification à deux facteurs',
            Name: 'Nom',
            Description: 'Description',
            Short_description: 'Brève description',
            Guard: 'Garde',
            State: 'Etat',
            Role: 'Rôle',
            CompanyName: "Nom de l'entreprise",
            CompanyLogo: "Logo de l'entreprise",
            Fullname: 'Nom et prénom',
            Firstname: 'Nom',
            Lastname: 'Prénom',
            MobileNumber: 'Téléphone',
            ZipCode: 'Code postal',
            confirmCode : 'Code de confirmation',
            RoomNumber: 'N° chambre',
            PassportNumber: 'N° passeport',
            Password: 'Mot de passe',
            Password_confirmation: 'Confirmer le mot de passe',
            Address: 'Adresse postale',
            City: 'Ville',
            Country: 'Pays',
            Edit: 'Éditer',
            Cancel: 'Annuler',
            Close: 'Fermer',
            Website: 'Site web',
            Admin: 'Administrateur',
            Accept_terms_conditions: 'J\'accepte les termes et conditions.',
            Alert_reservation_client_step2: 'Il est nécessaire de remplir votre panier pour passer à l\'étape suivante.',
            Alert_reservation_client_step3: 'Il faut être identifié pour passer a l\'étape suivant.',
            Payment_options: 'Options de paiement',
            Payment_options_description: "Assurez-vous de cliquer sur l'option de paiement correcte",
            Many: 'Plusieurs'
        },
        ch: {
            Starterkit: '入门套件',
            Dashboards: '儀表板',
            Classic: '經典',
            Analytical: '分析型',
            Ecommerce: '電子商務',
            General: '一般',
            Apps: '應用',
            Chats: '聊天室',
            Calendar: '日曆',
            ContactList: '聯繫人列表',
            ContactGrid: '接觸網格',
            Email: '電子郵件',
            Todo: '去做',
            UiElements: 'Ui元素',
            VuesaxComponents: 'Vuesax組件',
            Alert: '警報',
            Avatar: '頭像',
            Breadcrumb: '麵包屑',
            Buttons: '鈕扣',
            Cards: '牌',
            Chips: '薯片',
            Collapse: '坍方',
            Dialog: '對話',
            Divider: '分頻器',
            Dropdown: '落下',
            List: '清單',
            Loading: '載入中',
            Navbar: '導航欄',
            Notification: '通知',
            Numberinput: '號碼輸入',
            Pagination: '分頁',
            Popup: '彈出',
            Progress: '進展',
            Sidebar: '側邊欄',
            Slider: '滑桿',
            Tabs: '標籤',
            Tooltip: '工具提示',
            Forms: '形式',
            FormElements: '表單元素',
            Checkboxes: '選框',
            Datepicker: '日期選擇器',
            Input: '輸入項',
            Radio: '無線電',
            Select: '選擇',
            Switch: '開關',
            Textarea: '文字區',
            Upload: '上載',
            FormLayouts: '表單佈局',
            FormAction: '表單動作',
            FormBasic: '表格基本',
            FormHorizontal: '水平形式',
            FormWizard: '表單嚮導',
            DragnDrop: '拖放',
            TableExamples: '表示例',
            Basictable: '基本表',
            Editabletable: '可編輯表',
            ExpandDatatable: '展開數據表',
            Filtertable: '可過濾的',
            Miscellaneoustable: '雜項',
            Paginationtable: '分頁表',
            Selectedtable: '選定表',
            SSTtable: '穩定表',
            Charts: '圖表',
            ApexCharts: '頂點圖',
            ChartJs: '圖表',
            Icons: '圖示',
            MaterialIcons: '材質圖標',
            ThemifyIcons: '主題圖標',
            ExtraComponents: '額外組件',
            Carousel: '輪播',
            Widgets: '小部件',
            AppWidgets: '應用小部件',
            DataWidgets: '數據小部件'
        },
        gr: {
            Starterkit: 'Starter-Kit',
            Dashboards: 'Dashboards',
            Classic: 'Klassisch',
            Analytical: 'Analytische',
            Ecommerce: 'E-Commerce',
            General: 'Allgemeines',
            Apps: 'Apps',
            Chats: 'Chats',
            Civility: 'Civility',
            ProductList: 'Product List',
            Calendar: 'Kalender',
            ContactList: 'Kontaktliste',
            ContactGrid: 'Wenden Sie sich an Grid',
            Email: 'Email',
            Todo: 'Machen',
            UiElements: 'Ui-Elemente',
            VuesaxComponents: 'Vuesax-Komponenten',
            Alert: 'Warnen',
            Avatar: 'Benutzerbild',
            Breadcrumb: 'Paniermehl',
            Buttons: 'Tasten',
            Cards: 'Karten',
            Chips: 'Chips',
            Collapse: 'Zusammenbruch',
            Dialog: 'Dialog',
            Divider: 'Teiler',
            Dropdown: 'Dropdown-Liste',
            List: 'Liste',
            Loading: 'Wird geladen',
            Navbar: 'Navbar',
            Notification: 'Benachrichtigung',
            Numberinput: 'Zahleneingabe',
            Pagination: 'Seitennummerierung',
            Popup: 'Pop-up',
            Progress: 'Fortschritt',
            Sidebar: 'Seitenleiste',
            Slider: 'Schieberegler',
            Tabs: 'Tabs',
            Tooltip: 'Tooltip',
            Forms: 'Formen',
            FormElements: 'Formularelemente',
            Checkboxes: 'Kontrollkästchen',
            Datepicker: 'Datumsauswahl',
            Input: 'Eingang',
            Radio: 'Radio',
            Select: 'Wählen',
            Switch: 'Schalter',
            Textarea: 'Textarea',
            Upload: 'Hochladen',
            FormLayouts: 'Formularlayouts',
            FormAction: 'Formularaktion',
            FormBasic: 'Formular Basic',
            FormHorizontal: 'Formular Horizontal',
            FormWizard: 'Formzauberer',
            TableExamples: 'Tabellenbeispiele',
            Basictable: 'Basistabelle',
            Editabletable: 'Bearbeitungstabelle',
            ExpandDatatable: 'Erweitern Sie Datatable',
            Filtertable: 'Filtertisch',
            Miscellaneoustable: 'Verschiedenes',
            Paginationtable: 'Paginierungstabelle',
            Selectedtable: 'AusgewählteTabelle',
            SSTtable: 'SST-Tisch',
            Charts: 'Diagramme',
            ApexCharts: 'Scheitelpunktdiagramme',
            ChartJs: 'DiagrammJs',
            Icons: 'Icons',
            MaterialIcons: 'Materielle Ikonen',
            ThemifyIcons: 'Themify Ikonen',
            ExtraComponents: 'Zusätzliche Komponenten',
            Carousel: 'Karussell',
            Widgets: 'Widgets',
            AppWidgets: 'App-Widgets',
            DataWidgets: 'Daten-Widgets'
        },
    },

})