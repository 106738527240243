<template>
    <div id="app" :class="vueAppClasses">
      <router-view />
    </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'

export default {
  name: 'app',
  components: {

  },
  data () {
    return {
      vueAppClasses: []
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleBodyClass(val);
    }
  },
  methods: {
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    toggleBodyClass(className) {
      if (className == 'dark') {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    }
  },
  mounted() {
    this.toggleBodyClass(themeConfig.theme)

    this.$root.$on('setAppClasses', (classesStr) => {
      this.setAppClasses(classesStr)
    })
  },
  
}
</script>

<style lang="scss">

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
}

/* Track */
::-webkit-scrollbar-track {
    height: calc(100% - 10px);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(var(--vs-primary), 0.4);
  border-radius: 15px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--vs-primary), 0.5); 
  margin: 0px;
  height: 50%;
}

</style>