import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'
import store from '@/store/store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    base: '/',
    routes: [
        
        {
            path: '/error404',
            name: 'Error 404',
            component: () => import('./views/authentication/Error404.vue')
        }, 

        {
            path: '/order-cancelled',
            name: 'client-cancelled-order',
            component: () => import('./views/customer-reservation/cancelled-order/CancelledOrder.vue'),
        },

        {
            path: '/:slug?',
            component: () => import('./views/welcome-page/identifyTenant.vue'),
            
            children: [
                
        
                // ======================
                // Blank Layout : CLIENT
                // ======================    
              
                // {
                //     path: '/',
                //     name: 'welcomepage',
                //     component: () => import('./views/welcome-page/WelcomePageIndex.vue'),
                // },

                {
                    path: 'confirm-order/:ref',
                    name: 'client-confirm-order',
                    component: () => import('./views/customer-reservation/confirm-order/ConfirmOrder.vue'),
                },
               
                {
                    path: 'welcomepage*',
                    name: 'welcomepage-new',
                    component: () => import('./views/welcome-page/WelcomePageIndex1.vue'),
                },
                {
                    path: 'client/login',
                    name: 'reservation-client-login',
                    component: () => import('./views/welcome-page/reservation/Login.vue'),
                },
                {
                    path: 'client/payment',
                    name: 'reservation-client-payment',
                    component: () => import('./views/welcome-page/reservation/Payment.vue'),
                },
                {
                    path: 'welcome',
                    name: 'Customer-welcomepage',
                    index: 4,
                    component: () => import('./views/welcome-page/WelcomePageIndex.vue'),
                },
                // {
                //     path: 'reservation',
                //     name: 'Customer-reservation',
                //     index: 5,
                //     component: () => import('./views/customer-reservation/ReservationIndex.vue'),
                // },               
            ],

        },

        // Redirect to 404 page, if no match found
        // {
        //     path: '*',
        //     redirect: '/Error404'
        // }
    ]
})

import NProgress from 'nprogress';

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start()
    }
    next()
})

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

router.beforeEach((to, from, next) => {

    // If auth required, check login. If login fails redirect to login page
    if (to.meta.authRequired) {
        if ( !auth.isAuthenticatedAdmin() ) {
          router.push({ name: 'Login', params: {slug: to.params.slug} })
        }
        else {
            return next()
        }
    }
    // if (store.state.moduleSetting.customerSetting.length == 0) {
    //     NProgress.start()
    //     store.dispatch('moduleSetting/fetchReservationCustomerSettings').then(() => { 
    //         NProgress.done()
    //         next()
    //     })    
    // }
    else {
        return next()
    }
})

export default router