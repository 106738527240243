/*=========================================================================================
  File Name: moduleEcommerceMutations.js
  Description: Ecommerce Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  TOGGLE_ITEM_IN_WISH_LIST (state, item) {
    const index = state.wishList.findIndex(i => i.id === item.id)
    if (index < 0) {
      state.wishList.push(item)
    } else {
      state.wishList.splice(index, 1)
    }
  },
  REMOVE_ITEM_FROM_CART (state, payload) {

    var index = payload.index
    
    console.log('payload', payload)
    console.log('index', index)

    if (payload.item && payload.item.reservationProductUuid) {
      index = state.cartItems.findIndex(i => i.reservationProductUuid === payload.item.reservationProductUuid)
    }
    // else {
    //   index = state.cartItems.findIndex(i => i.id === payload.item.id)
    // }
    
    if (payload.item && payload.item.reservationProductUuid) {
      state.destroyProductsUuid.push(payload.item.reservationProductUuid)
    }

    console.log('state.destroyProductsUuid', state.destroyProductsUuid)

    if (index > -1) { state.cartItems.splice(index, 1) }
  },
  ADD_ITEM_IN_CART (state, item) {
    state.cartItems.push(Object.assign({}, item))
  },
  ADD_AV_PRODUCT_LIST (state, items) {
    state.avProductsList = items
  },
  ADD_DATE_DIFF (state, dateDifference) {
    state.dateDifference = dateDifference
  },
  ADD_PRODUCTSHEET_SUGGESTION (state, items) {

    console.log('ADD_PRODUCTSHEET_SUGGESTION items', items)
    console.log('state.prodsheetSuggestion', state.prodsheetSuggestion)
    console.log('length', state.prodsheetSuggestion.length)

    if(state.prodsheetSuggestion.length == 0) {
      state.prodsheetSuggestion = items
    }
    else{
      var data = state.prodsheetSuggestion.data.concat(items.data)
      state.prodsheetSuggestion = items
      state.prodsheetSuggestion.data = data
    }
  },
  CLEAR_PRODUCTSHEET_SUGGESTION (state) {
    state.prodsheetSuggestion = []
  },
  TOGGLE_LOADING_AV_PRODUCT_LIST (state, bool) {
    state.loadingAvProductsList = bool
  },
  TOGGLE_LOADING_CATEG_LIST (state, bool) {
    state.loadingCategList = bool
  },
  TOGGLE_LOADING_PAY_STRIPE (state, bool) {
    state.paymentProcessing = bool
  },
  ADD_PRODUCT_IN_CART (state) {

    console.log('state.porductPopup', state.porductPopup)

    const selectedProductOption = []
    if(state.porductPopup.productOptionsList) {
      state.porductPopup.productOptionsList.forEach((element) => {
        selectedProductOption.push({
          option_id: element.option_id,
          title: element.title,
          quantityRequested: element.quantityRequested,
          price: parseFloat(element.price).toFixed(2),
          total: parseFloat(element.price * element.quantityRequested).toFixed(2),
          qty: element.qty,
          img: element.image ? element.image.thumbnail : null,
          active: element.active
        })
      })
    }

    const storeProductItemInCart = {
      id: state.porductPopup.id,
      calculated: state.porductPopup.calculated,
      product_id: state.porductPopup.id,
      img: state.porductPopup.image ? state.porductPopup.image.thumbnail : null,
      img_medium: state.porductPopup.image ? state.porductPopup.image.medium : null,
      title: state.porductPopup.title,
      total: parseFloat(state.porductPopup.total).toFixed(2),
      product_price: parseFloat(state.porductPopup.price).toFixed(2),
      total_product: parseFloat(state.porductPopup.total_product).toFixed(2),
      total_options_price: parseFloat(state.porductPopup.options_price).toFixed(2),
      quantityRequested: state.porductPopup.quantityRequested,
      selectedStartEndDateTime: state.porductPopup.selectedStartEndDateTime,
      allOptions: selectedProductOption,
      product_options: selectedProductOption,
    }
    
    state.cartItems.push(Object.assign({}, storeProductItemInCart))
  },
  ADD_EMPTY_ROW_IN_CART (state) {
    state.cartItems.push(Object.assign({}, {}))
  },
  UPDATE_WIZARD_STEPS_BAR(state, index) {
    state.wizardStepsBar[index].active = true
    state.wizardStepsBar[index].finished = true

    state.wizardStepsBar.forEach((element, indexElement) => {

      if(index >= indexElement) {

        state.wizardStepsBar[indexElement].active = true
        state.wizardStepsBar[indexElement].finished = true
      }
      else {
        state.wizardStepsBar[indexElement].active = false
        state.wizardStepsBar[indexElement].finished = false
      }

    });
  },
  UPDATE_ITEM_IN_CART (state) {
    
    const index = state.indexSelectedItemInCart
    console.log('index',index)

    let cartItems = state.cartItems;
    console.log('state.cartItems[payload.index]', cartItems[index] )

    const selectedProductOption = []
    let productOptionsPrice = 0
    if(state.porductPopup.productOptionsList) {
      state.porductPopup.productOptionsList.forEach((element) => {

        productOptionsPrice = Number(productOptionsPrice) + (Number(element.quantityRequested) * Number(element.price))

        selectedProductOption.push({
          option_id: element.option_id,
          title: element.title,
          quantityRequested: element.quantityRequested,
          price: parseFloat(element.price).toFixed(2),
          total: parseFloat(element.price * element.quantityRequested).toFixed(2),
          qty: element.qty,
          img: element.x_small_image ? element.x_small_image : element.img,
          active: element.active
        })

        if(!element.active) {
          const findProductOption = state.porductPopup.product_options.find(i => i.option_id === element.option_id)

          console.log('findProductOption', findProductOption)
          // console.log('findProductOption', findProductOption.reservationProductOptionUuid)

          if (findProductOption && findProductOption.reservationProductOptionUuid) {
            state.destroyProductOptionsUuid.push(findProductOption.reservationProductOptionUuid)
          }

          console.log('state.destroyProductOptionsUuid', state.destroyProductOptionsUuid)
          
        }

      })
    }

    console.log('state.porductPopup', state.porductPopup)

    var product_price = state.porductPopup.product_price ? state.porductPopup.product_price : state.porductPopup.price

    cartItems[index].title = state.porductPopup.title
    cartItems[index].description = state.porductPopup.description
    cartItems[index].isByProductsheet = state.porductPopup.isByProductsheet
    cartItems[index].meduim_image = state.porductPopup.meduim_image
    cartItems[index].small_image = state.porductPopup.small_image
    cartItems[index].price = parseFloat(product_price).toFixed(2)
    cartItems[index].product_price = parseFloat(product_price).toFixed(2)
    cartItems[index].product_id = state.porductPopup.id
    cartItems[index].productId = state.porductPopup.id
    cartItems[index].total_product = parseFloat(state.porductPopup.total_product).toFixed(2)
    cartItems[index].options_price = parseFloat(productOptionsPrice).toFixed(2)
    cartItems[index].total = parseFloat(state.porductPopup.total).toFixed(2),
    cartItems[index].quantityRequested = state.porductPopup.quantityRequested
    cartItems[index].productsheets = state.porductPopup.productsheets ? state.porductPopup.productsheets : null
    cartItems[index].allOptions = selectedProductOption
    cartItems[index].productOptionsList = selectedProductOption
    cartItems[index].product_options = selectedProductOption
    cartItems[index].selectedStartEndDateTime = state.porductPopup.selectedStartEndDateTime
    cartItems[index].calculated = state.porductPopup.calculated
  },
  ADD_SELECTED_START_END_DATETIME_IN_CART (state, item) {
    state.selectedStartEndDateTime = []; // clear selectedStartEndDateTime 
    state.selectedStartEndDateTime.push(Object.assign({}, item))
  },
  UPDATE_ITEM_QUANTITY (state, payload) {
    state.cartItems[payload.index].quantity = payload.quantity
  },
  CHANGE_SELECTED_CART_ITEM_INDEX (state, index) {
    state.indexSelectedItemInCart = index
  },
  CHANGE_THE_VALUE_Of_UPDATE_BTN (state, boolValue) {
    state.isCartUpdate = boolValue
  },
  /*
  UPDATE_ITEM_IN_CART (state, payload) {
    console.log('FROM UPDATE_ITEM_IN_CART payload', payload)
    console.log('state.cartItems[payload.index]', state.cartItems[payload.index])

    let cartItems = state.cartItems;

    cartItems[payload.index].quantityRequested = payload.quantityRequested
    cartItems[payload.index].requested_dates = payload.requested_dates
    cartItems[payload.index].requested_product_options = {}
    cartItems[payload.index].requested_product_options = payload.requested_product_options
    cartItems[payload.index].price = payload.item.price
  },
  */
  BTN_UPDATE_ITEM_CART (state, payload) {
    console.log('FROM BTN_UPDATE_ITEM_CART payload', payload.btn_update_cart_item)
    console.log('state.btn_update_cart_item', state.btn_update_cart_item)

    state.btn_update_cart_item = payload.btn_update_cart_item
  },
  SET_CATEG_LIST (state, items) {
    state.categList = items
  },
  SET_CART_UID (state, uid) {
    state.uidCart = uid
  },
  SET_PAYMENT_METHOD (state, paymentMethodId) {
    state.paymentMethod = paymentMethodId
  },
  SET_SELECTED_CLIENT_NEW_CONTRACT (state, item) {
    state.selectedClientNewContract = item
  },
  CHANGE_PRODUCT_QTY_POPUP (state, qty) {
    console.log('qty FROM CHANGE_PRODUCT_QTY_POPUP', qty)
    state.porductPopup.quantityRequested = qty

    console.log('state.porductPopup', state.porductPopup)

    var product_price = state.porductPopup.product_price ? state.porductPopup.product_price : state.porductPopup.price
    state.porductPopup.total_product = parseFloat(Number(product_price) * Number(state.porductPopup.quantityRequested)).toFixed(2)

    // calcul total product options
    if(state.porductPopup.productOptionsList != null) {
      let productOptionsPrice = 0
      state.porductPopup.productOptionsList.forEach((option) => {
        productOptionsPrice = Number(productOptionsPrice) + (Number(option.quantityRequested) * Number(option.price))
      })

      state.porductPopup.options_price = parseFloat(productOptionsPrice).toFixed(2)
    }
    
    state.porductPopup.total = parseFloat(Number(state.porductPopup.total_product) + Number(state.porductPopup.options_price)).toFixed(2)
  },
  UPDATE_PRODUSHEET_QUICK (state, payload) {
    const index = state.indexSelectedItemInCart

    if(!state.cartItems[index].productsheets) state.cartItems[index].productsheets = []

    if(payload.checked) {
      state.cartItems[index].productsheets.push({
        active: payload.checked,
        numero_serie: payload.numero_serie,
        serial: payload.serial,
      });

      state.cartItems[index].quantityRequested = ++state.cartItems[index].quantityRequested
    }
    else {
      console.log('payload', payload)
      const _productsheetIndex = state.cartItems[index].productsheets.findIndex((u) => u.serial === payload.serial)
      state.cartItems[index].productsheets.splice(_productsheetIndex, 1)

      state.cartItems[index].quantityRequested = --state.cartItems[index].quantityRequested
    }

    var product_price = state.cartItems[index].product_price ? state.cartItems[index].product_price : state.cartItems[index].price
    state.cartItems[index].total_product = parseFloat(Number(product_price) * Number(state.cartItems[index].quantityRequested)).toFixed(2)

    // calcul total product options
    if(state.cartItems[index].productOptionsList != null) {
      let productOptionsPrice = 0
      state.cartItems[index].productOptionsList.forEach((option) => {
        productOptionsPrice = Number(productOptionsPrice) + (Number(option.quantityRequested) * Number(option.price))
      })

      state.cartItems[index].options_price = parseFloat(productOptionsPrice).toFixed(2)
    }

    state.cartItems[index].total = parseFloat(Number(state.cartItems[index].total_product) + Number(state.cartItems[index].options_price)).toFixed(2)
  },
  CHANGE_PRODUCT_QTY_QUICK (state, payload) {

    const product = state.cartItems[payload.index]

    console.log('product', product)

    if(product.calculated >= payload.quantityRequested) {
      var product_price = product.product_price ? product.product_price : product.price
      state.cartItems[payload.index].total_product = parseFloat(Number(product_price) * Number(payload.quantityRequested)).toFixed(2)
      state.cartItems[payload.index].quantityRequested = payload.quantityRequested
    }

    // calcul total product options
    if(product.productOptionsList != null) {
      let productOptionsPrice = 0
      product.productOptionsList.forEach((option) => {
        productOptionsPrice = Number(productOptionsPrice) + (Number(option.quantityRequested) * Number(option.price))
      })

      state.cartItems[payload.index].options_price = parseFloat(productOptionsPrice).toFixed(2)
    }
    
    state.cartItems[payload.index].total = parseFloat(Number(state.cartItems[payload.index].total_product) + Number(state.cartItems[payload.index].options_price)).toFixed(2)
  },
  CHANGE_PRODUCT_DATETIME_QUICK(state, payload) {

    const index = state.indexSelectedItemInCart
    const product = state.cartItems[index]
    var total_product = parseFloat(Number(payload.data.price) * Number(payload.quantityRequested)).toFixed(2)

    state.cartItems[index].total_product = total_product
    state.cartItems[index].price = parseFloat(Number(payload.data.price)).toFixed(2)
    state.cartItems[index].product_price = parseFloat(Number(payload.data.price)).toFixed(2)
    state.cartItems[index].calculated = payload.data.calculated
    state.cartItems[index].product_options = payload.data.product_options

    // calcul total product options
    if(product.productOptionsList != null) {
      let productOptionsPrice = 0
      product.productOptionsList.forEach((option) => {
        const productOption = payload.data.product_options.find((e) => Number(e.id) === Number(option.option_id))
        option.active = false
        option.qty = productOption.qty
        option.price = parseFloat(Number(productOption.price)).toFixed(2)
        productOptionsPrice = Number(productOptionsPrice) + (Number(option.quantityRequested) * Number(productOption.price))
      })

      state.cartItems[index].options_price = parseFloat(productOptionsPrice).toFixed(2)
    }
    
    state.cartItems[index].total = parseFloat(total_product + Number(state.cartItems[index].options_price)).toFixed(2)
  },
  CHANGE_PRODUCTOPTION_QTY_QUICK (state, payload) {

    let index = state.cartItems[payload.index].productOptionsList.findIndex(i => i.option_id === payload.productOption.option_id)
    state.cartItems[payload.index].productOptionsList[index].quantityRequested = payload.productOption.quantityRequested
    state.cartItems[payload.index].productOptionsList[index].active = payload.productOption.active

    var product_price = state.cartItems[payload.index].product_price ? state.cartItems[payload.index].product_price : state.cartItems[payload.index].price
    state.cartItems[payload.index].total_product = parseFloat(Number(product_price) * Number(state.cartItems[payload.index].quantityRequested)).toFixed(2)

    // calcul total product options
    if(state.cartItems[payload.index].productOptionsList != null) {
      let productOptionsPrice = 0
      state.cartItems[payload.index].productOptionsList.forEach((option) => {
        productOptionsPrice = Number(productOptionsPrice) + Number(option.quantityRequested) * Number(option.price)
      })
      state.cartItems[payload.index].options_price = parseFloat(productOptionsPrice).toFixed(2)
    }
    
    state.cartItems[payload.index].total = Number(state.cartItems[payload.index].total_product) + Number(state.cartItems[payload.index].options_price)
    
    state.cartItems.push('force_update');
    state.cartItems.splice(-1, 1);
  
  },
  CHANGE_SELECTED_START_END_DATE_POPUP(state, selectedStartEndDateTime) {
    state.porductPopup.selectedStartEndDateTime = selectedStartEndDateTime
  },
  CHANGE_PRODUCTOPTION_QTY_POPUP (state, payload) {
    console.clear()
    console.log('CHANGE_PRODUCTOPTION_QTY_POPUP', payload)

    let index = state.porductPopup.productOptionsList.findIndex(i => i.option_id === payload.productOption.option_id)
    state.porductPopup.productOptionsList[index].quantityRequested = payload.productOption.quantityRequested
    state.porductPopup.productOptionsList[index].active = payload.productOption.active

    var product_price = state.porductPopup.product_price ? state.porductPopup.product_price : state.porductPopup.price

    console.log('product_price', product_price)
    console.log('state.porductPopup.price', state.porductPopup.price)
    console.log('state.porductPopup.product_price ', state.porductPopup.product_price)

    state.porductPopup.total_product = parseFloat(Number(product_price) * Number(state.porductPopup.quantityRequested)).toFixed(2)

    // calcul total product options
    if(state.porductPopup.productOptionsList != null) {
      let productOptionsPrice = 0
      state.porductPopup.productOptionsList.forEach((option) => {
        console.log('option', option)
        productOptionsPrice = Number(productOptionsPrice) + Number(option.quantityRequested) * Number(option.price)
      })
      state.porductPopup.options_price = parseFloat(productOptionsPrice).toFixed(2)
    }
    
    state.porductPopup.total = Number(state.porductPopup.total_product) + Number(state.porductPopup.options_price)
  },
  CREATE_EMPTY_POPUP (state, data) {
    console.log('data FROM CREATE_EMPTY_POPUP', data)
    if(data != null) {
      var quantityRequestedProuct = data.quantityRequested ? data.quantityRequested : 1
      var product_price = data.product_price ? data.product_price : data.price
      data.total_product = parseFloat(Number(product_price) * Number(quantityRequestedProuct)).toFixed(2)
    }
    
    const optionList = []
    let totalOptionsPrice = 0
    data.product_options.forEach(element => {

      console.log('element', element)
      let quantityRequestedOption = element.quantityRequested ? element.quantityRequested : 0
      totalOptionsPrice = parseFloat(Number(totalOptionsPrice) + Number(quantityRequestedOption * element.price)).toFixed(2)

      optionList.push({
        option_id: element.option_id ? element.option_id : element.id,
        title: element.title,
        quantityRequested: quantityRequestedOption,
        price: parseFloat(element.price).toFixed(2),
        total: element.quantityRequested ? parseFloat(element.price).toFixed(2) : 0,
        qty: element.qty,
        img: element.x_small_image ? element.x_small_image : element.img,
        active: element.quantityRequested ? true : false
      })
    });

    data.total = parseFloat(Number(totalOptionsPrice) + (Number(product_price) * Number(quantityRequestedProuct))).toFixed(2)

    data.productOptionsList = optionList
    state.porductPopup = data
    console.log('state.porductPopup ', state.porductPopup )
  },
  CREATE_EMPTY_POPUP2 (state, data) {
    console.log('data FROM CREATE_EMPTY_POPUP', data)

    var quantityRequestedProuct = data.quantityRequested ? data.quantityRequested : 1
    var product_price = data.product_price ? data.product_price : data.price
    data.total_product = parseFloat(Number(product_price) * Number(quantityRequestedProuct)).toFixed(2)

    data.productsheets = data.productsheets ? data.productsheets : null
    data.isByProductsheet = data.isByProductsheet ? data.isByProductsheet : false

    const optionList = []
    let totalOptionsPrice = 0

    if(data.product_options) {
      data.product_options.forEach(element => {
  
        console.log('element', element)
        let quantityRequestedOption = element.quantityRequested ? element.quantityRequested : 0
        totalOptionsPrice = parseFloat(Number(totalOptionsPrice) + Number(quantityRequestedOption * element.price)).toFixed(2)
  
        optionList.push({
          option_id: element.option_id ? element.option_id : element.id,
          title: element.title,
          quantityRequested: quantityRequestedOption,
          price: parseFloat(element.price).toFixed(2),
          total: element.quantityRequested ? parseFloat(element.price).toFixed(2) : 0,
          qty: element.qty,
          img: element.x_small_image ? element.x_small_image : element.img,
          active: element.quantityRequested ? true : false
        })
      });
    }

    data.total = parseFloat(Number(totalOptionsPrice) + (Number(product_price) * Number(quantityRequestedProuct))).toFixed(2)
    data.productOptionsList = optionList
    state.porductPopup = data

    console.log('state.porductPopup', state.porductPopup)
  },
  STORE_PRODUCT_QUICK_CART (state, data) {
    state.cartItems.push(Object.assign({}, data))
  },
  CLEAR_CART (state) {
    state.cartItems = []
    state.uidCart = ''
    state.orderID= ''
    state.activePrompt = false
  },
  CLEAR_SELECTED_CLIENT_CONRTACT (state) {
    state.selectedClientNewContract = null
  },
  SET_ID (state,id) {
    state.orderID= id
    state.activePrompt = true
  },
  SET_APPLY_COUPON (state, applyCoupon) {
    state.applyCouponResponse = applyCoupon
  },
  SET_COUPON_CODE (state, couponCode) {
    state.couponCode = couponCode
  },
  CLEAR_COUPON_CODE (state) {
    state.couponCode = ''
    state.applyCouponResponse = null
  },
  CANCEL_APPLY_COUPON (state) {
    state.applyCouponResponse = null
  },
  TOGGLE_IS_WITHOUT_DICOUNT_ACTIVE (state, val) {
    state.isWithoutDiscountActive = val ? val : !state.isWithoutDiscountActive
  },
}

