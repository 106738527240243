/*=========================================================================================
  File Name: moduleEcommerceState.js
  Description: Ecommerce Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

/* eslint-disable */
export default {
  cartItems: [],
  avProductsList: [],
  dateDifference: null,
  prodsheetSuggestion: [],
  categList: [],
  loadingAvProductsList: false,
  loadingCategList: false,
  loading: false,
  paymentProcessing: false,
  uidCart: '',
  paymentMethod: null,
  porductPopup: [],
  selectedStartEndDateTime: [],
  wishList: [],
  btn_update_cart_item: false,
  btn_delete_cart_item: false,
  selectedClientNewContract: null,
  indexSelectedItemInCart: null,
  isCartUpdate: false,
  destroyProductsUuid: [],
  destroyProductOptionsUuid: [],
  orderID:'',
  activePrompt:false,
  applyCouponResponse: null,
  couponCode: '',
  isWithoutDiscountActive: 1,
  wizardStepsBar: [
    {
      svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>',
      text : 'MES DATES',
      active: false,
      finished: false
    },
    {
      svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>',
      text : 'LISTE DES PRODUITS',
      active: false,
      finished: false
    },
    {
      svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" /></svg>',
      text : 'CONNEXION',
      active: false,
      finished: false
    },
    {
      svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
      text : 'PANIER',
      active: false,
      finished: false
    },
  ]
}
/* eslint-enable */