/*=========================================================================================
  File Name: moduleSettingManagement.js
  Description: Setting Module
  ----------------------------------------------------------------------------------------
  Item Name: Lokmi v2.1
  Author: BAKLOUTI HOUSSEM
  COMPANY NAME: NETNOVUS
==========================================================================================*/

import axios from '@/axios.js'
import router from '@/router'

export default {
  fetchCustomerSettings ({ commit } ) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v4/admin/customer-config`)
        .then((response) => {
          commit('SET_CUSTOMER_SETTINGS', response.data)
          if(response.data.config.adminTheme){
            const adminTheme= response.data.config.adminTheme 
            commit('SET_THEME', adminTheme.theme,{ root: true })
            commit('SET_THEME_COLOR', adminTheme.themeColor ,{ root: true })
            commit('SET_TOPBAR_COLOR', adminTheme.topbarColor ,{ root: true })
          }
          
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  checkCompany ({ commit }, payload ) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/check-company?apikey=${payload.apikey}&by=${payload.by}`)
        .then((response) => {
          console.log('response', response)
          console.log('response.data.uuid', response.data.uuid)
          localStorage.setItem('companyUuid', response.data.uuid)
          commit('SET_COMPANY_SETTINGS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchReservationCustomerSettings ({ commit, state } ) {

    return new Promise((resolve, reject) => {
      axios.get(`/api/reservation/customer_config`)
        .then((response) => {
          commit('SET_RESERVATION_SETTINGS', response.data)
          // this part should be changed to FrontTheme
          if(response.data.config.adminTheme){
            const adminTheme= response.data.config.adminTheme 
            commit('SET_THEME_COLOR', adminTheme.themeColor ,{ root: true })
            commit('SET_TOPBAR_COLOR', adminTheme.topbarColor ,{ root: true })
          }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateCustomerSettings ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v4/admin/customer-config`, payload.updateCustomer)
        .then((response) => {
          // commit('SET_SETTINGS', response.data)
          resolve(response)
        })
        .catch((error) => { 
          payload.notify({
            time: 8000,
            title: 'Erreur',
            text: "Erreur de sauvegarde",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position:'top-right'
          })
         reject(error) 
        })
    })
  },
  removeMedia ({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {

      payload.loading()

      axios.delete(`/api/v4/admin/customer/media/destroy/${payload.imgUuid}?media_uuid=${payload.imgUuid}`)
        .then((response) => {
          payload.loading.close()
          
          payload.notify({
            time: 8000,
            title: 'Success',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            position:'top-right'
          })
          resolve(response)
        })
        .catch((error) => {

          payload.loading.close()

          if(error.response.data.errors != null) {
            let errors = Object.values(error.response.data.errors) 
            errors.forEach(error => 
              payload.notify({
                time: 8000,
                title: 'Erreur',
                text: error[0],
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                position:'top-right'
              })
            );
          }
          else if(error.response.data.message != null) {
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          }

          reject(error) 
        })
    })
  },
  removeMediaBySection ({commit}, payload) {
    return new Promise((resolve, reject) => {

      payload.loading()

      axios.delete(`/api/v4/admin/customer/media/destroy-by-section`, {params: payload.params})
        .then((response) => {
          payload.loading.close()
          
          payload.notify({
            time: 8000,
            title: 'Success',
            text: response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
            position:'top-right'
          })
          resolve(response)
        })
        .catch((error) => {

          payload.loading.close()

          if(error.response.data.errors != null) {
            let errors = Object.values(error.response.data.errors) 
            errors.forEach(error => 
              payload.notify({
                time: 8000,
                title: 'Erreur',
                text: error[0],
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                position:'top-right'
              })
            );
          }
          else if(error.response.data.message != null) {
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          }

          reject(error) 
        })
    })
  },



}
