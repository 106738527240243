import Vue from 'vue'
import Vuex from 'vuex'
import moment from "moment";
import themeConfig from '@/../themeConfig.js'
import mails from '@/views/apps/email/emails'

import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleSetting from '@/store/settings/moduleSettingManagement.js'
const getAdminInfo = () => { return JSON.parse(localStorage.getItem('adminInfo')) || {} }
const getClientInfo = () => { return JSON.parse(localStorage.getItem('clientInfo')) || [] }

Vue.use(Vuex)
/* eslint-disable */
export default new Vuex.Store({
  state: {
    theme: themeConfig.theme,
    
  	isSidebarActive: false,
  	cartNavbar: false,
  	isSidebarReduced: themeConfig.sidebarCollapsed,
  	sidebarWidth: "default",
    themeColor: themeConfig.themeColor,
    topbarColor: themeConfig.topbarColor,
    windowTop: 0,
  	showMenu: false,
  	company: '',

    // Calendar Events
    calendarEvents: [{
      title: "test",
      allDay: true,
      start: moment(),
      end: moment().add(1, "d")
    }],

    // Email States
    emails: mails,
    filtered_mail: "Inbox",
    AppActiveClient: getClientInfo(),
    AppActiveAdmin: getAdminInfo()

  },
  mutations: {
  	//This is for Sidbar trigger in mobile
  	IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
  	IS_CART_NAVBAR_ACTIVE(state, value) {
        state.cartNavbar = value;
    },
    //This is for Sidebar toggle in desktop
    TOGGLE_REDUCE_SIDEBAR(state, val) {
        state.isSidebarReduced = val;
    },
    //This is for Themeset
    SET_THEME(state, val) {
        state.theme = val;
    },
    //This is for Theme color
    SET_THEME_COLOR(state, val) {
        state.themeColor = val;
    },
    //This is for Topbar color
    SET_TOPBAR_COLOR(state, val) {
      state.topbarColor = val;
    },
    SET_WINDOW_TOP(state, val) {
      state.windowTop = val;
    },
    SET_SHOW_MENU(state, val) {
      state.showMenu = val;
    },
    SET_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    // Add Calendar event
    ADD_CALENDAR_EVENT(state, event) {
      const newEvent = Object.assign({}, event);
      state.calendarEvents.push(newEvent);
    },

    // Filter emails
    FILTERED_MAIL(state, filterCategory) {
      state.filtered_mail = filterCategory
    },

    SET_CLIENT_INFO (state, data) {
      var exist = state.AppActiveClient && state.AppActiveClient.length>0 ? state.AppActiveClient.find((c) => c.company_uuid === data.company_uuid) : false
      console.log('exist', exist)
      
      if(!exist) {
        state.AppActiveClient.push(data);

        // Store data in localStorage
        localStorage.setItem('clientInfo', JSON.stringify(state.AppActiveClient))
      }
    },

    // Set company slug
    SET_COMPANY_INFO (state, data) {
      state.company = data
    },

    SET_ADMIN_INFO (state, data) {
      state.AppActiveAdmin = data

      // Store data in localStorage
      localStorage.setItem('adminInfo', JSON.stringify(data))
    },

    UPDATE_ADMIN_GOOGLE_2FA_IS_ENABLED_COL (state, google2fa_is_enabled) {
      state.AppActiveAdmin.google2fa_is_enabled = google2fa_is_enabled
    },
  },  
  actions: {
  	updateSidebarWidth({ commit }, width) {
        commit('SET_SIDEBAR_WIDTH', width);
    },
    //Calendar Action 
    addCalendarEvent({ commit }, event) {
        commit('ADD_CALENDAR_EVENT', event);
    },
    
    // Email Action
    filteredMail({ commit }, filterCategory){
        commit('FILTERED_MAIL', filterCategory)
    },
    
  },
  modules: {
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    moduleSetting: moduleSetting
  },
  getters:{
    // Get Filtered Email
    getFilteredMails: state => state.emails.filter((email) => {
      if (state.filtered_mail == 'Inbox') return email.mail_type == "inbox";
      if (state.filtered_mail == 'Spam') return email.mail_type == "spam"; 
      if (state.filtered_mail == 'Draft') return email.mail_type == "draft";
      if (state.filtered_mail == 'Sent') return email.mail_type == "sent";
    }),
    getCurrentMail: state => (emailId) => state.emails.filter((email) => email.id == emailId)[0],
    getInboxCount: state => state.emails.filter((email) => email.mail_type == 'inbox').length,
    getActiveClientForCurrentCompany: state => {
      // if(state.company=='' || state.AppActiveClient.length==0) return null
      console.log('state.AppActiveClient', state.AppActiveClient)
      console.log('state.company', state.company)
      return state.AppActiveClient.find((c) => c.company_uuid === state.company.uuid)
    },
  }
})
