/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'
import axios from '@/axios.js'
import router from '@/router'

export default {
  loginAttempt ({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

  },
  login ({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }
   
  },
  
  // Social Login
  SocialLogin ({commit, state}, payload) {
    
    if (state.isUserLoggedIn()) {

      payload.loading.close()
      
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    
    return new Promise((resolve, reject) => {

      axios.post(`/api/v3/sociallogin/${payload.provider}`, payload.response)
        .then((response) => {

          console.log('response', response)
          
          payload.loading.close()
          payload.loading.close('#popup-login > .con-vs-loading')
          
          // If there's user data in response
          if (response.data) {

            // Set accessToken
            // localStorage.setItem('clientAccessToken', response.data.data.accessToken)

            // Set client details
            commit('SET_CLIENT_INFO', response.data.clientData, {root: true})

            // Set bearer token in axios
            // commit('SET_BEARER', response.data.data.accessToken)

            resolve(response)
          } else {
            reject({message: 'User is unavailable. Try another social account!'})
          }
        })
        .catch((error) => {

          console.log('error.response.data.message=', error.response.data.message)

          payload.loading.close()
          payload.loading.close('#popup-login > .con-vs-loading')

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          if(error.response.data.errors != null) {
            let errors = Object.values(error.response.data.errors) 
            errors.forEach(error => 
              payload.notify({
                time: 8000,
                title: 'Erreur',
                text: error[0],
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            );
          }
          else if(error.response.data.message != null) {
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          }
          reject(error) 
        })
    })

  },
  
  // Admin Login
  adminLogin ({commit, state}, payload) {

    payload.loading()
    
    return new Promise((resolve, reject) => {

      axios.post(`/api/v4/admin/login`, payload.loginForm)
        .then((response) => {

          console.log('response', response.data)

          payload.loading.close()

          // If there's user data in response
          if (response.status == 200) {

            if(response.data.access_token != '') {
              // Set accessToken
              localStorage.setItem('adminAccessToken', response.data.access_token)

              console.log('router.currentRoute', router.currentRoute.params.slug)

              // ${router.currentRoute.params.slug}
  
              router.push(router.currentRoute.query.to || `/${router.currentRoute.params.slug}/admin/dashboard`)

              // Set bearer token in axios
              commit('SET_ADMIN_BEARER', response.data.access_token)
            }
            else {
              router.push('/checkpoint/otp')
            }

            // Set admin details
            commit('SET_ADMIN_INFO', response.data.user, {root: true})

            resolve(response)
          } else {
            reject({message: "Les informations d'identification fournies sont incorrectes."})
          }
        })
        .catch((error) => {

          payload.loading.close()

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          if(error.response.data.errors != null) {
            let errors = Object.values(error.response.data.errors) 
            errors.forEach(error => 
              payload.notify({
                time: 8000,
                title: 'Error',
                text: error[0],
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            );
          }
          else if(error.response.data.message != null) {
            payload.notify({
              time: 8000,
              title: 'Error',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          }
          reject(error) 
        })
    })

  },

  adminLogOut () {

    if (localStorage.getItem('adminAccessToken')) {
      localStorage.removeItem('adminAccessToken')
    }

    localStorage.removeItem('adminInfo')

    router.push(`/${router.currentRoute.params.slug}/login`).catch(() => {})
  },

  clientLogOut ({ rootState }) {

    rootState.AppActiveClient = []

    if (localStorage.getItem('clientInfo')) { localStorage.removeItem('clientInfo') }
  },
  
  registerClient ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      jwt.registerClient(payload.userDetails)
        .then(response => {

          console.log('response', response.data)

          payload.loading.close()

          // If there's user data in response
          if (response.data) {

            // Set accessToken
            // localStorage.setItem('clientAccessToken', response.data.data.accessToken)

            // Set client details
            commit('SET_CLIENT_INFO', response.data.clientData, {root: true})

            // Set bearer token in axios
            // commit('SET_BEARER', response.data.data.accessToken)

            resolve(response)
          } else {
            reject({message: 'Mauvais email ou mot de passe'})
          }
        })

        // If error notify
        .catch(function (err) {

          console.log('err', err)

          payload.loading.close()

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          let errors = Object.values(err.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          );

        })
       
    })
  },

  loginClient ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.loginClient(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          console.log('response', response)

          // If there's user data in response
          if (response.data) {
            payload.loading.close()

            // Set accessToken
            // localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            commit('SET_CLIENT_INFO', response.data.clientData, {root: true})

            // Set bearer token in axios
            // commit('SET_BEARER', response.data.accessToken)

            resolve(response)
          } else {
            reject({message: 'Mauvais email ou mot de passe'})
          }
        })
        // If error notify
        .catch(function (err) {
          payload.loading.close()

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 8000,
            title: 'Erreur',
            text: Object.values(err.response.data.errors).flat().join(),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position:'top-right'
          })
        })
               
    })
  },

  resetPassword ({ commit }, payload) {

    return new Promise((resolve, reject) => {

      axios.post(`/api/v4/sendresetcode`, payload.data)
        .then((response) => {

          console.log('response', response)
          payload.loading.close()
          
          if (response.data && response.data.result == true) {
            payload.notify({
              time: 8000,
              title: 'Succès',
              text: 'Email envoyé avec succès.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
              position: 'top-right'
            })
            resolve(response)
          } else {
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: response.data.message == "email not found" ? "Email non trouvé." : "Une erreur s'est produite veuillez réessayer ultérieurement.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position: 'top-right'
            })
          }
        })
        .catch((error) => {

          console.log('error.response.data.message=', error.response.data.message)

          payload.loading.close()
          
          payload.notify({
            time: 8000,
            title: 'Erreur',
            text: "Une erreur s'est produite veuillez réessayer ultérieurement.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-right'
          })

          reject(error) 
        })
    })
  },

  sendConfirmCode ({ commit }, payload) {

    return new Promise((resolve, reject) => {

      axios.post(`/api/v4/checkCodeResetClient`, payload.data)
        .then((response) => {

          console.log('response', response)
          payload.loading.close()

          if (response.data && response.data.result == true) {
            payload.notify({
              time: 8000,
              title: 'Succès',
              text: 'Code valide, Saisissez votre nouveau mot de passe.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
              position: 'top-right'
            })
            resolve(response)
          } else {
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: response.data.message == "code non valid" ? "Code invalide." : "Une erreur s'est produite veuillez réessayer ultérieurement.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position: 'top-right'
            })
          }
        })
        .catch((error) => {

          console.log('error.response.data.message=', error.response.data.message)

          payload.loading.close()
          
          payload.notify({
            time: 8000,
            title: 'Erreur',
            text: "Une erreur s'est produite veuillez réessayer ultérieurement.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-right'
          })

          reject(error) 
        })
    })
  },

  sendResetPassword ({ commit }, payload) {

    return new Promise((resolve, reject) => {

      axios.post(`/api/v4/ResetClientPasswordByCode`, payload.data)
        .then((response) => {

          console.log('response', response)
          payload.loading.close()

          if (response.data && response.data.result == true) {
            payload.notify({
              time: 8000,
              title: 'Succès',
              text: 'Le mot de passe a été changé avec succès.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
              position: 'top-right'
            })
            resolve(response)
          } else {
            payload.notify({
              time: 8000,
              title: 'Erreur',
              text: "Une erreur s'est produite veuillez réessayer ultérieurement.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position: 'top-right'
            })
          }
        })
        .catch((error) => {

          console.log('error.response.data.message=', error.response.data.message)

          payload.loading.close()

          payload.notify({
            time: 8000,
            title: 'Erreur',
            text: "Une erreur s'est produite veuillez réessayer ultérieurement.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-right'
          })

          reject(error) 
        })
    })
  },
  
  adminRegisterClient ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      
      jwt.adminRegisterClient(payload.userDetails)
        .then(response => {

          console.log('response', response.data)

          payload.loading.close()

          // If there's user data in response
          if (response.data) {
            resolve(response)
          } else {
            reject({message: 'Error'})
          }
        })

        // If error notify
        .catch(function (err) {

          payload.loading.close()

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          let errors = Object.values(err.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Error',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          );

        })
       
    })
  },

  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },

  getOTP({ commit }, payload) {
    return new Promise((resolve, reject) => {

      payload.loading()
      
      axios.get('/api/v4/admin/otp')
        .then((response) => {
          commit('SET_OTP', response.data)
          resolve(response)
          payload.loading.close()
        })
        .catch((error) => {
          reject(error)
          payload.loading.close()
        })
    })
  },

  enableOTP({ commit }, payload) {
    return new Promise((resolve, reject) => {

      payload.loading()
      
      axios.post('/api/v4/admin/otp', payload.data)
        .then((response) => {
          resolve(response)
          commit('UPDATE_ADMIN_GOOGLE_2FA_IS_ENABLED_COL', true, {root: true})
          payload.loading.close()
        })
        .catch((error) => {
          
          payload.loading.close()

          let errors = Object.values(error.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Error',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          );


          reject(error)
        })
    })
  },

  disable2Fa({ commit }, payload) {
    return new Promise((resolve, reject) => {

      payload.loading()
      
      axios.delete('/api/v4/admin/otp/otp', {data: payload.data})
        .then((response) => {
          resolve(response)
          commit('UPDATE_ADMIN_GOOGLE_2FA_IS_ENABLED_COL', false, {root: true})
          payload.loading.close()
        })
        .catch((error) => {
          
          payload.loading.close()

          let errors = Object.values(error.response.data.errors)
          errors.forEach(error => 
            payload.notify({
              time: 8000,
              title: 'Error',
              text: error[0],
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
              position:'top-right'
            })
          );


          reject(error)
        })
    })
  },

  submitCheckpointOtp({ commit }, payload) {
    return new Promise((resolve, reject) => {

      payload.loading()
      
      axios.post('/api/v4/admin/checkpoint/validate-2fa', payload.data)
        .then((response) => {

          if(response.data.access_token != '') {
            // Set accessToken
            localStorage.setItem('adminAccessToken', response.data.access_token)

            router.push(router.currentRoute.query.to || '/admin/dashboard')

            // Set bearer token in axios
            commit('SET_ADMIN_BEARER', response.data.access_token)

            commit('SET_ADMIN_INFO', response.data.user, {root: true})

            // commit('UPDATE_ADMIN_', true, {root: true})
          }

          resolve(response)
          payload.loading.close()
        })
        .catch((error) => {
          
          payload.loading.close()

          if(error.response.data.errors != null) {
            let errors = Object.values(error.response.data.errors)
            errors.forEach(error => 
              payload.notify({
                time: 8000,
                title: 'Error',
                text: error[0],
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger',
                position:'top-right'
              })
            );
          }

          reject(error)
        })
    })
  },


  // JWT
  loginJWT ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {

          // If there's user data in response
          if (response.data.userData) {
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || '/')

            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken)

            resolve(response)
          } else {
            reject({message: 'Mauvais email ou mot de passe'})
          }

        })
        .catch(error => { reject(error) })
    })
  },
  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  }
}
