/*=========================================================================================
  File Name: moduleSettingManagement.js
  Description: Setting Module
  ----------------------------------------------------------------------------------------
  Item Name: Lokmi v2.1
  Author: BAKLOUTI HOUSSEM
  COMPANY NAME: NETNOVUS
==========================================================================================*/

import state from './moduleSettingManagementState.js'
import mutations from './moduleSettingManagementMutations.js'
import actions from './moduleSettingManagementActions.js'
import getters from './moduleSettingManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

